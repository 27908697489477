import React, { FC } from "react"

import MainLayout from "../../../common/components/layouts/MainLayout"
import PageHead from "../../../common/components/page-header/PageHeader"
import BlackListPage from "../../../common/components/pages/settings/black-list/BlackList"

const BlackList: FC = () => {
    return (
        <MainLayout pageTitle="Черный список">
            <div className="page-wrapper">
                <PageHead title="Черный список" />

                <BlackListPage />
            </div>
        </MainLayout>
    )
}

export default BlackList