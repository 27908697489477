import axios, { AxiosResponse } from "axios"

import {IAdsListPayload, IPhoto} from "../../../types/ads"
import { axiosConfigAuth } from "../middleware"
import {
    API_ADS_DATA, API_ADS_DOWNLOAD_PHOTO,
    API_ADS_GET_FILTER_LISTS,
    API_ADS_PROCESS_PHOTO,
    API_FAV_ADD,
    API_FAV_DELETE,
    API_FAV_LIST
} from "../urls"
import {showSuccessNotification} from "../../utils/ui";
import {getAccessToken} from "../../utils/auth";

export const adsApi = {
    fetchData: async (data: IAdsListPayload): Promise<AxiosResponse> => {
        return axios.post(API_ADS_DATA, data, axiosConfigAuth())
    },

    fetchLists: async (): Promise<AxiosResponse> => {
        return axios.get(API_ADS_GET_FILTER_LISTS, axiosConfigAuth())
    },

    fetchFavoritesData: async (data: IAdsListPayload): Promise<AxiosResponse> => {
        return axios.post(API_FAV_LIST, data, axiosConfigAuth())
    },

    addFavoritesAd: async (id: string | number): Promise<AxiosResponse> => {
        return axios.get(`${API_FAV_ADD}/${id}`, axiosConfigAuth())
    },
    processPhoto: async (data: IPhoto): Promise<AxiosResponse> => {
        return axios.post(API_ADS_PROCESS_PHOTO, data, axiosConfigAuth())
    },
    downloadPhoto: async (data: IPhoto): Promise<void> => {
        const a = document.createElement("a")
        a.href = `${API_ADS_DOWNLOAD_PHOTO}/${data.item_id}/${data.source_id}/${getAccessToken()}`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        showSuccessNotification("Архив успешно скачен.")

    },
    deleteFavoritesAd: async (id: string | number): Promise<AxiosResponse> => {
        return axios.get(`${API_FAV_DELETE}/${id}`, axiosConfigAuth())
    },
}
