import React, { FC, useEffect, useState } from "react"

import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons"
import { Button, Modal, Space, Table } from "antd"
import { ColumnsType } from "antd/es/table"

import AddModal from "./AddModal"
import EditModal from "./EditModal"
import { successTagColor, errorTagColor, allTagColors } from "../../../constants/colors"
import { useActions } from "../../../hooks/useActions"
import { useTypedSelector } from "../../../hooks/useTypedSelector"
import ColorTag from "../../ui/color-tag/ColorTag"

const StaffPage: FC = () => {
    let timeoutId: any

    const [ addModalOpen, setAddModalOpen ] = useState(false)
    const [ editModalOpen, setEditModalOpen ] = useState(false)
    const [ editId, setEditId ] = useState(0)
    const [ isLoading, setIsLoading ] = useState(false)

    const { staffData } = useTypedSelector((state) => state.staff)
    const { staffFetchData, staffDelete, currentUserGetStatus } = useActions()

    const getColor = (index: number) => {
        return allTagColors[index % allTagColors.length]
    }

    const bindTableRequest = () => {
        for (let i = 0; i < 999999; i++) {
            clearInterval(i)
        }

        setIsLoading(true)
        staffFetchData(() => {setIsLoading(false)})

        if (timeoutId) {
            clearInterval(timeoutId)
        } else {
            for (let i = 0; i < 999999; i++) {
                clearInterval(i)
            }

            setInterval(() => {
                currentUserGetStatus()
            }, 1000)
        }

        // timeoutId = setInterval(() => {
        //     staffFetchData()
        // }, 5000)
    }

    useEffect(() => {
        bindTableRequest()
    }, [])

    const staffColumns: ColumnsType<any> = [
        {
            title: "Логин",
            dataIndex: "username",
            key: "username",
        },
        {
            title: "ФИО",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Группа",
            dataIndex: "group",
            key: "group",
        },
        {
            title: "Статус пользователя",
            dataIndex: "status",
            key: "status",
            width: 100,
            render: (value) => value === "Готов" || value === "Не в сети" ? (
                <ColorTag color={value === "Готов" ? successTagColor : errorTagColor}>
                    {value}
                </ColorTag>
            ) : (
                <ColorTag defaultColor>
                    {value}
                </ColorTag>
            )
        },
        {
            title: "WS статус",
            dataIndex: "ws_status",
            key: "ws_status",
            width: 100,
            render: (value) => value === "Готов" || value === "Не в сети" ? (
                <ColorTag color={value === "Готов" ? successTagColor : errorTagColor}>
                    {value}
                </ColorTag>
            ) : (
                <ColorTag defaultColor>
                    {value}
                </ColorTag>
            )
        },
        {
            title: "Роль",
            dataIndex: "role",
            key: "role",
        },
        {
            title: "IP",
            dataIndex: "ip_address",
            key: "ip_address",
        },
        {
            title: "Локация автозвонка",
            dataIndex: "locations",
            key: "locations",
            render: (value) => {
                const renderedList = `${value}`?.split(", ")?.map((item, index) => { 
                    const color = getColor(index)

                    return item !== "null" ? (
                    <ColorTag key={index} color={color}>
                        {item}
                    </ColorTag>
                    ) : null
                })

                return (
                    <>
                        {renderedList}
                    </>
                )}
        },

        {
            title: "",
            dataIndex: "actions",
            key: "actions",
            width: 60,
            render: (value, record) => (
                <Space>
                    <Button
                        icon={<EditOutlined />}
                        onClick={() => {
                            setEditId(record.id)
                            setEditModalOpen(true)
                        }}
                    />

                    <Button
                        icon={<DeleteOutlined />}
                        onClick={() => {
                            Modal.confirm({
                                title: "Вы уверены что хотите удалить сотрудника?",
                                icon: <ExclamationCircleOutlined/>,
                                okText: "Да",
                                okType: "danger",
                                cancelText: "Нет",
                                onOk() {
                                    staffDelete(record.id, () => {
                                        staffFetchData()
                                    })
                                },
                            })
                        }}
                        danger
                    />
                </Space>
            )
        },
    ]

    return (
        <div className="page-content">
            <Button
                type="primary"
                onClick={() => setAddModalOpen(true)}
            >
                Добавить сотрудника
            </Button>

            <Table
                dataSource={staffData}
                columns={staffColumns}
                scroll={{ x: 870 }}
                style={{ marginTop: 24 }}
                pagination={false}
                loading={{
                    spinning: isLoading,
                    indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />
                }}
                bordered
            />

            <AddModal
                open={addModalOpen}
                setOpen={setAddModalOpen}
            />

            <EditModal
                id={editId}
                open={editModalOpen}
                setOpen={setEditModalOpen}
            />
        </div>
    )
}

export default StaffPage
