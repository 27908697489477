import React, { FC, useEffect, useState } from "react"

import { LoadingOutlined } from "@ant-design/icons"
import { Form, Input, Modal, Select, Spin } from "antd"
import { useDispatch } from "react-redux"

import { IListGeneric } from "../../../../types/main"
import { IUpdateStaffPayload } from "../../../../types/staff"
import { staffApi } from "../../../api/endpoints/staffApi"
import { handleResponseError } from "../../../api/middleware"
import { useActions } from "../../../hooks/useActions"
import { useTypedSelector } from "../../../hooks/useTypedSelector"
import { showFormErrors, showSuccessNotification } from "../../../utils/ui"

interface Props {
    open: boolean
    setOpen: (value: boolean) => void
    id: number
}

const EditModal: FC<Props> = ({ open, setOpen, id }) => {

    const dispatch = useDispatch()

    const [ form ] = Form.useForm()

    const [ roles, setRoles ] = useState<IListGeneric[]>([])
    const [ groups, setGroups ] = useState<IListGeneric[]>([])
    const [ rolesLoading, setRolesLoading ] = useState(false)
    const [ groupsLoading, setGroupsLoading ] = useState(false)

    const { isLoading } = useTypedSelector((state) => state.staff)
    const { staffUpdateUser, staffFetchData, setStaffLoading } = useActions()

    useEffect(() => {
        if (open && id) {
            getRolesList()
            getGroupsList()
            getUpdateData(id)
        }
    }, [ open ])

    const getRolesList = () => {
        setRolesLoading(true)

        staffApi.fetchRolesList()
            .then((response) => {
                if (response.status === 200) {
                    setRoles(response.data)
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
            })
            .finally(() => {
                setRolesLoading(false)
            })
    }

    const getGroupsList = () => {
        setGroupsLoading(true)

        staffApi.fetchGroupsList()
            .then((response) => {
                if (response.status === 200) {
                    setGroups(response.data)
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
            })
            .finally(() => {
                setGroupsLoading(false)
            })
    }

    const getUpdateData = (userId: number) => {
        setStaffLoading(true)

        staffApi.fetchUpdateData(userId)
            .then((response) => {
                if (response.status === 200 && response.data) {
                    const newValues = Object.entries(response.data).map(([ key, val ]) => {
                        if (key === "password") {
                            return { name: key, value: "" }
                        }

                        return { name: key, value: val }
                    })

                    form.setFields(newValues)
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
            })
            .finally(() => {
                setStaffLoading(false)
            })
    }

    const updateStaff = (values: IUpdateStaffPayload) => {
        const parsedValues = {
            ...values,
            id: id,
            password: values.password || null
        }

        staffUpdateUser(parsedValues, () => {
            setOpen(false)
            showSuccessNotification("Данные пользователя обновлены")
            staffFetchData()
        }, (errors) => {
            showFormErrors(form, errors)
        })
    }

    return (
        <Modal
            title="Редактирование сотрудника"
            open={open}
            onOk={() => form.submit()}
            onCancel={() => setOpen(false)}
            okText="Сохранить"
            cancelText="Отмена"
            okButtonProps={{ loading: isLoading }}
            destroyOnClose
        >
            {
                isLoading ?
                    (
                        <div className="global-loading-container" style={{ height: 400 }}>
                            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                        </div>
                    ) : (
                        <Form
                            className="un-form__wrapper"
                            form={form}
                            onFinish={updateStaff}
                            layout="vertical"
                            requiredMark={false}
                        >
                            <Form.Item
                                label="ФИО"
                                name="name"
                                rules={[ { required: true, message: "Поле обязательное" } ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Логин пользователя"
                                name="username"
                                rules={[ { required: true, message: "Поле обязательное" } ]}
                            >
                                <Input disabled />
                            </Form.Item>

                            <Form.Item
                                label="Новый пароль (не обязательное поле)"
                                name="password"
                            >
                                <Input.Password autoComplete="new-password" />
                            </Form.Item>

                            <Form.Item
                                label="Группа"
                                name="group_id"
                            >
                                <Select loading={groupsLoading}>
                                    {
                                        groups.map((item, index) => (
                                            <Select.Option value={item.id} key={index}>
                                                {item.name}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Роль"
                                name="role_id"
                            >
                                <Select disabled loading={rolesLoading}>
                                    {
                                        roles.map((item, index) => (
                                            <Select.Option value={item.id} key={index}>
                                                {item.name}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="IP Адрес"
                                name="ip_address"
                                rules={[ { required: true, message: "Поле обязательное" } ]}
                            >
                                <Input />
                            </Form.Item>
                        </Form>
                    )
            }
        </Modal>
    )
}

export default EditModal
