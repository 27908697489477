import axios, { AxiosResponse } from "axios"

import { axiosConfigAuth } from "../middleware"
import { API_BL_DEL, API_BL_ADD, API_BL_GET_DATA } from "../urls"

export const blApi = {
    fetchData: (): Promise<AxiosResponse> => {
        return axios.get(API_BL_GET_DATA, axiosConfigAuth())
    },

    add: async (id: number, source_id: number): Promise<AxiosResponse> => {
        const data = {
            value: id,
            source_id: source_id
        }

        return axios.post(API_BL_ADD, data, axiosConfigAuth())
    },

    delete: (value: string, id: number): Promise<AxiosResponse> => {
        const data = {
            value: value,
            source_id: id
        }

        return axios.post(API_BL_DEL, data, axiosConfigAuth())
    }
}