import Fingerprint2 from "fingerprintjs2"
import UAParser from "ua-parser-js"

export const saveAccessToken = (token: string): void => {
    localStorage.setItem("access", token)
}

export const getAccessToken = (): string => {
    const _token = localStorage.getItem("access") || ""

    return _token !== "undefined" && typeof _token !== "undefined" ? _token : ""
}

export const saveRefreshToken = (token: string): void => {
    localStorage.setItem("refresh", token)
}

export const getRefreshToken = (): string => {
    const _token = localStorage.getItem("refresh") || ""

    return _token !== "undefined" && typeof _token !== "undefined" ? _token : ""
}

export const clearStorage = (): void => {
    localStorage.clear()
}

export const getFingerPrint = async (): Promise<string> => {
    return new Promise((resolve, reject) => {
        const getHash = async () => {
            const options = {
                excludes: {
                    plugins: true,
                    localStorage: true,
                    adBlock: true,
                    screenResolution: true,
                    availableScreenResolution: true,
                    enumerateDevices: true,
                    pixelRatio: true,
                    doNotTrack: true,
                    preprocessor: (key: string, value: any) => {
                        if (key === "userAgent") {
                            const parser = new UAParser(value)

                            return `${parser.getOS().name} :: ${parser.getBrowser().name} :: ${parser.getEngine().name}`
                        }
                        return value
                    }
                }
            }

            try {
                const components = await Fingerprint2.getPromise(options)
                const values = components.map(component => component.value)

                return String(Fingerprint2.x64hash128(values.join(""), 31))
            } catch (e) {
                reject(e)
                return ""
            }
        }

        if (requestIdleCallback) {
            requestIdleCallback(async () => resolve(await getHash()))
        } else {
            setTimeout(async () => resolve(await getHash()), 500)
        }
    })
}

export const _parseTokenData = (accessToken: string): any => {
    let payload = ""
    let tokenData = {}

    try {
        if (accessToken) {
            payload = accessToken.split(".")[1]
            tokenData = JSON.parse(atob(payload))
        }
    } catch (error) {
        throw new Error(`${error}`)
    }

    return tokenData
}

export const isAccessTokenExpired = (token: string): boolean => {
    const expDate = _parseTokenData(token).exp
    const accessTokenExpDate = expDate - 10

    const nowTime = Math.floor(new Date().getTime() / 1000)

    return accessTokenExpDate <= nowTime
}
