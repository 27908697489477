import React, { FC, useEffect, useMemo, useState } from "react"

import { Button, Descriptions, Modal, Space } from "antd"
import TextArea from "antd/es/input/TextArea"

import { callStatus } from "../../../constants/statuses"
import { useActions } from "../../../hooks/useActions"
import { useTypedSelector } from "../../../hooks/useTypedSelector"
import { showErrorNotification } from "../../../utils/ui"
import MetroView from "../../ui/metro-view/MetroView"

const CallWrapper: FC = ({ children }) => {
    const openSound = useMemo(() => new Audio("/assets/ring.mp3"), []) 

    const [ callModalOpen, setCallModalOpen ] = useState(false)
    const [ callLoading, setCallLoading ] = useState(false)

    const { currentCall } = useTypedSelector((state) => state.currentUser)
    const { currentUserSetCall, currentUserSendCallStatus } = useActions()

    const [ note, setNote ] = useState(currentCall?.notes || "")

    useEffect(() => {
        if (currentCall?.id && !callModalOpen) {
            setCallModalOpen(true)
            openSound.play()
        }
    }, [ currentCall ])

    const sendStatus = (status: number) => {
        if (currentCall && status) {
            setCallLoading(true)

            currentUserSendCallStatus(currentCall.id, status, note, () => {
                currentUserSetCall(null)
                setCallModalOpen(false)
                setCallLoading(false)
            }, () => {
                showErrorNotification("Ошибка при отправке статуса объявления, повторите попытку")
                setCallLoading(false)
            })
        }
    }

    return (
        <>
            {children}

            <Modal
                title="Текущий вызов"
                open={callModalOpen}
                onCancel={() => setCallModalOpen(false)}
                width={700}
                footer={
                    <Space direction="horizontal">
                        <Button
                            type="primary"
                            style={{ backgroundColor: "#14a65f" }}
                            disabled={callLoading}
                            onClick={() => sendStatus(callStatus.our_flat)}
                        >
                            Квартира наша
                        </Button>
                        <Button
                            type="primary"
                            style={{ backgroundColor: "#dc4a3d" }}
                            disabled={callLoading}
                            onClick={() => sendStatus(callStatus.not_our)}
                        >
                            Не снял
                        </Button>
                        <Button
                            type="primary"
                            style={{ backgroundColor: "#14c1ec" }}
                            disabled={callLoading}
                            onClick={() => sendStatus(callStatus.agent)}
                        >
                            Агент
                        </Button>
                        <Button
                            type="primary"
                            style={{ backgroundColor: "#f29a2e" }}
                            disabled={callLoading}
                            onClick={() => sendStatus(callStatus.not_callable)}
                        >
                            Не дозвонился
                        </Button>
                        <Button
                            type="primary"
                            style={{ backgroundColor: "#dc4a3d" }}
                            disabled={callLoading}
                            onClick={() => sendStatus(callStatus.not_first)}
                        >
                            Не первые
                        </Button>
                    </Space>
                }
                closable={false}
                maskClosable={false}
            >
                <Descriptions
                    title=""
                    layout="horizontal"
                    style={{ margin: "40px 0" }}
                    bordered
                >
                    <Descriptions.Item label="Объявление" span={3}>
                        <span dangerouslySetInnerHTML={{ __html: currentCall?.title || "" }} />
                    </Descriptions.Item>
                    <Descriptions.Item label="Адрес" span={3}>
                        {currentCall?.address}
                    </Descriptions.Item>
                    <Descriptions.Item label="Метро" span={3}>
                        <MetroView
                            title={currentCall?.metro?.name}
                            color={`#${currentCall?.metro?.color}`}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label="Стоимость" span={3}>
                        {currentCall?.price}
                    </Descriptions.Item>
                    <Descriptions.Item label="Телефон" span={3}>
                        {currentCall?.phone}
                    </Descriptions.Item>
                    <Descriptions.Item label="Заметка" span={3}>
                        <TextArea
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                        />
                    </Descriptions.Item>
                </Descriptions>
            </Modal>
        </>
    )
}

export default CallWrapper