import { adsSliceActions } from "./adsSlice"
import { adsApi } from "../../../common/api/endpoints/adsApi"
import { blApi } from "../../../common/api/endpoints/blApi"
import { mainApi } from "../../../common/api/endpoints/mainApi"
import { handleResponseError } from "../../../common/api/middleware"
import { IAdsListPayload } from "../../../types/ads"
import { TAppDispatch } from "../../store"

export const adsActions = {
    ...adsSliceActions,

    adsFetchData: (data: IAdsListPayload, withouLoading: boolean, onFinish?: () => void) => async (dispatch: TAppDispatch) => {
        !withouLoading && dispatch(adsActions.setAdsLoading(true))

        adsApi.fetchData(data)
            .then((response) => {
                if (response.status === 200) {
                    const meta = {
                        total: response.data.total,
                        totalPages: response.data.totalPages,
                        start: response.data.start,
                        end: response.data.end,
                        currentPage: response.data.currentPage,
                        pageSize: response.data.pageSize
                    }
                    let rows: any[] = Array.from(response.data.rows)
                    try {
                        rows.some((v) => v)
                    } catch (e) {
                        rows = []
                    }

                    const banners = response.data.banner

                    dispatch(adsActions.setAdsTableData({ rows, meta }))
                    dispatch(adsActions.setAdsBannerData(banners))
                    onFinish && onFinish()
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
            })
            .finally(() => {
                !withouLoading && dispatch(adsActions.setAdsLoading(false))
            })
    },

    adsAddToBlackList: (id: number, source_id: number, onFinish: () => void) => async (dispatch: TAppDispatch) => {
        dispatch(adsActions.setAdsLoading(true))

        blApi.add(id, source_id)
            .then((response) => {
                if (response.status === 200) {
                    onFinish()
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
            })
            .finally(() => {
                dispatch(adsActions.setAdsLoading(false))
            })
    },

    adsGetCurrentAdData: (id: number, onFinish: (data: any) => void, onError: () => void) => async (dispatch: TAppDispatch) => {
        mainApi.fetchCallData(id)
            .then((response) => {
                if (response.status === 200) {
                    onFinish(response.data)
                }
            })
            .catch((err) => {
                onError()
                handleResponseError(dispatch, err)
            })
    },

    adsSendCall: (id: number) => async (dispatch: TAppDispatch) => {
        dispatch(adsActions.setAdsLoading(true))

        mainApi.sendCall(id)
            .catch((err) => {
                handleResponseError(dispatch, err, {
                    400: "Проверьте подключение к приложению и повторите попытку"
                })
            })
            .finally(() => {
                dispatch(adsActions.setAdsLoading(false))
            })
    }
}
