import React, { FC, useEffect, useState } from "react"

import { HeartOutlined, LoadingOutlined, SelectOutlined } from "@ant-design/icons"
import { Button, Space } from "antd"
import Table, { ColumnsType } from "antd/es/table"

import { IAdsListPayload } from "../../../../types/ads"
import { adsApi } from "../../../api/endpoints/adsApi"
import { useActions } from "../../../hooks/useActions"
import { useTypedSelector } from "../../../hooks/useTypedSelector"
import { showErrorNotification, showSuccessNotification } from "../../../utils/ui"
import MetroView from "../../ui/metro-view/MetroView"
// import EditModal from "../ads/EditModal"
import {
    SourceView,
    // StatusView
} from "../ads/ExtraComponents"

const FavoritePage: FC = () => {
    // const [ editOpen, setEditOpen ] = useState(false)
    // const [ editId, setEditId ] = useState(0)
    const [ isFetch, setIsFetch ] = useState(false)

    const { isLoading, isFetching, tableData, tableMetaData } = useTypedSelector((state) => state.favorite)
    const {
        favoriteFetchData,
        setFavoriteFetching,
    // favoriteSendCall
    } = useActions()

    const staffColumns: ColumnsType<any> = [
        {
            title: "Дата и время",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "Заголовок",
            dataIndex: "title",
            key: "title",
            render: (value, record) => (
        <Space direction="horizontal">
          <Button
              size="small"
              type="primary"
              icon={<HeartOutlined />}
              style={{ marginRight: 5 }}
              onClick={() => {
                  adsApi.deleteFavoritesAd(record.id)
                      .then((response) => {
                          if (response.status === 200) {
                              showSuccessNotification("Объявление удалено из избранного")
                              setIsFetch(true)
                          }
                      })
                      .catch(() => {
                          showErrorNotification("Ошибка при удалении из избранного, повтороите попытку")
                      })
              }}
          />

          <a href={value.link} target="_blank">
            <Button
                size="small"
                icon={<SelectOutlined />}
                style={{ marginRight: 5 }}
            />
          </a>

          <span>
            {value.name}
          </span>
        </Space>
            )
        },
        {
            title: "Источник",
            dataIndex: "source",
            key: "source",
            render: (value) => {
                return value && (
                    <>
            <SourceView source={value.name} />
                    </>
                )
            }
        },
        {
            title: "Стоимость",
            dataIndex: "price",
            key: "price",
        },
        {
            title: "Адрес",
            dataIndex: "address",
            key: "address",
            render: (value) => (
                <>
          <div>{value.name}</div>
          <MetroView
              title={value.metro.name}
              color={`#${value.metro.color}`}
          />
                </>
            )
        },
        {
            title: "Контакт",
            dataIndex: "phone",
            key: "phone",
        },
    // {
    //   title: "Ответственный",
    //   dataIndex: "user",
    //   key: "user",
    // },
    // {
    //   title: "Статус",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (value, record) => value && (
    //     <StatusView
    //       status={value}
    //       id={record.id}
    //       setEditOpen={setEditOpen}
    //       setEditId={setEditId}
    //       sendCall={favoriteSendCall}
    //     />
    //   )
    // },
    ]

    const fetchData = (paginationData = { page: 1, pageSize: 10, sorting: { name: "ASC" } }, withouLoading = false, onFinish?: () => void) => {
        const data: IAdsListPayload = {
            page: paginationData.page,
            pageSize: paginationData.pageSize,
            sorting: paginationData.sorting,
            filters: undefined,
        }

        favoriteFetchData(data, withouLoading, onFinish)
    }

    const bindTableRequest = (paginationData = { page: 1, pageSize: 10, sorting: { name: "ASC" } }) => {
        fetchData(paginationData)
    }

    useEffect(() => {
        bindTableRequest()
    }, [])

    useEffect(() => {
        if (isFetching) {
            bindTableRequest()
            setFavoriteFetching(false)
        }
    }, [ isFetching ])

    useEffect(() => {
        if (isFetch) {
            bindTableRequest({
                page: tableMetaData.currentPage,
                pageSize: tableMetaData.pageSize,
                sorting: { name: "ASC" }
            })
            setIsFetch(false)
        }
    }, [ isFetch ])

    return (
    <div style={{ marginBottom: 150 }} className="page-content">
      <Table
          dataSource={tableData || []}
          columns={staffColumns}
          scroll={{ x: 870 }}
          rowClassName={(record) => record.removed ? "table__row_red" : ""}
          loading={{
              spinning: isLoading,
              indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />
          }}
          pagination={{
              position: [ "bottomLeft" ],
              showSizeChanger: true,
              pageSizeOptions: [ "10", "25", "50", "100" ],
              defaultCurrent: 1,
              defaultPageSize: 10,
              current: tableMetaData?.currentPage || 1,
              total: tableMetaData.total,
              onChange: (page, per_page) => bindTableRequest({
                  page: page,
                  pageSize: per_page,
                  sorting: { name: "ASC" }
              }),
              onShowSizeChange: (page, per_page) => bindTableRequest({
                  page: page,
                  pageSize: per_page,
                  sorting: { name: "ASC" }
              }),
              showTotal: (total, range) =>
                  `${range[0]}-${range[1]} из ${total} объявлений`,
          }}
          size="small"
          bordered
      />

      {/*<EditModal*/}
      {/*  open={editOpen}*/}
      {/*  setOpen={setEditOpen}*/}
      {/*  id={editId}*/}
      {/*/>*/}
    </div>
    )
}

export default FavoritePage
