import React, { FC, useEffect, useState } from "react"

import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons"
import { Button, Modal, Space, Table } from "antd"
import { ColumnsType } from "antd/es/table"

import AddModal from "./AddModal"
import EditModal from "./EditModal"
import { useActions } from "../../../hooks/useActions"
import { useTypedSelector } from "../../../hooks/useTypedSelector"

const AccessPage: FC = () => {

    const [ addModalOpen, setAddModalOpen ] = useState(false)
    const [ openEditModal, setOpenEditModal ] = useState(false)
    const [ editData, setEditData ] = useState({
        id: 0,
        desc: "",
        address: ""
    })

    const { accessData, isLoading } = useTypedSelector((state) => state.access)
    const { accessFetchData, accessDelete, accessChangeMode } = useActions()

    useEffect(() => {
        accessFetchData()
    }, [])

    const staffColumns: ColumnsType<any> = [
        {
            title: "#",
            dataIndex: "id",
            key: "id",
            width: 50
        },
        {
            title: "IP адрес",
            dataIndex: "address",
            key: "address",
        },
        {
            title: "Описание",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Дата добавления",
            dataIndex: "created_at",
            key: "created_at",
        },
        {
            title: "Дата обновления",
            dataIndex: "updated_at",
            key: "updated_at",
        },

        {
            title: "",
            dataIndex: "actions",
            key: "actions",
            width: 60,
            render: (value, record) => (
                <Space>
                    <Button
                        icon={<EditOutlined />}
                        onClick={() => {
                            setEditData({
                                id: record.id,
                                address: record.address,
                                desc: record.description
                            })
                            setOpenEditModal(true)
                        }}
                    />

                    <Button
                        icon={<DeleteOutlined />}
                        onClick={() => {
                            Modal.confirm({
                                title: "Вы уверены что хотите удалить доступ?",
                                icon: <ExclamationCircleOutlined/>,
                                okText: "Да",
                                okType: "danger",
                                cancelText: "Нет",
                                onOk() {
                                    accessDelete(record.id, () => {
                                        accessFetchData()
                                    })
                                },
                            })
                        }}
                        danger
                    />
                </Space>
            )
        },
    ]

    return (
        <div className="page-content">
            <Button type="primary" onClick={() => setAddModalOpen(true)}>
                Добавить доступ
            </Button>

            <Table
                dataSource={accessData.data}
                columns={staffColumns}
                scroll={{ x: 870 }}
                style={{ margin: "24px 0" }}
                loading={{
                    spinning: isLoading,
                    indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />
                }}
                // scroll={{ x: 1300 }}
                pagination={false}
                bordered
            />

            {
                accessData.access ?
                    <Button
                        type="default"
                        onClick={() => accessChangeMode(0, () => {accessFetchData()})}
                        loading={isLoading}
                        danger
                    >
                        Выключить авторизацию
                    </Button>
                    :
                    <Button
                        type="default"
                        onClick={() => accessChangeMode(1, () => {accessFetchData()})}
                        loading={isLoading}
                        className="btn__success"
                    >
                        Включить авторизацию
                    </Button>
            }

            <AddModal
                open={addModalOpen}
                setOpen={setAddModalOpen}
            />

            <EditModal
                open={openEditModal}
                setOpen={setOpenEditModal}
                id={editData.id}
                address={editData.address}
                description={editData.desc}
            />
        </div>
    )
}

export default AccessPage
