import { currentUserSliceActions } from "./currentUserSlice"
import { mainApi } from "../../../common/api/endpoints/mainApi"
import { handleResponseError } from "../../../common/api/middleware"
import { showErrorNotification } from "../../../common/utils/ui"
import { TAppDispatch } from "../../store"
import { appActions } from "../app/actions"

export const currentUserActions = {
    ...currentUserSliceActions,

    currentUserGetData: (onFinish: () => void, onError: () => void) => async (dispatch: TAppDispatch) => {
        // dispatch(appActions.setAppLoading(true))

        mainApi.getUserInfo()
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data

                    dispatch(currentUserActions.currentUserSetData({
                        id: data.id,
                        name: data.name,
                        username: data.username,
                        group: data.group_id,
                        role: data.role_id
                    }))

                    onFinish()
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
                onError()
            })
    },

    currentUserSendCallStatus: (id: number, status: number, note: string, onFinish: () => void, onError: () => void, operator_id?: number) => async () => {
        mainApi.sendCallStatus(id, status, note, operator_id)
            .then((response) => {
                if (response.status === 200) {
                    onFinish()
                }
            })
            .catch(() => {
                onError()
            })
    },

    currentUserGetStatus: (onFinish?: () => void, onError?: () => void) => async (dispatch: TAppDispatch) => {
        mainApi.getUserStatus()
            .then((response) => {
                if (response.status === 200) {
                    dispatch(currentUserActions.currentUserSetCall(response.data.queue))
                    dispatch(currentUserActions.currentUserSetAppStatus(response.data.connection.ws_status))
                    dispatch(currentUserActions.currentUserSetPhoneStatus(response.data.connection.status))
                }
                onFinish && onFinish()
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
                onError && onError()
            })
    },

    currentUserGetLocations: (onFinish?: () => void) => async (dispatch: TAppDispatch) => {
        dispatch(currentUserActions.currentUserSetLocationsLoading(true))

        mainApi.getUserLocations()
            .then((response) => {
                if (response.status === 200) {
                    dispatch(currentUserActions.currentUserSetLocationList(response.data))
                    onFinish && onFinish()
                }
            })
            .catch(() => {
                showErrorNotification("Ошибка при загрузке локаций. Перезагрузите страницу.")
            })
            .finally(() => {
                dispatch(currentUserActions.currentUserSetLocationsLoading(false))
                dispatch(appActions.setAppLoading(false))
            })
    },

    currentUserUpdatePhoneStatus: (status: 2 | 4) => async (dispatch: TAppDispatch) => {
        dispatch(currentUserActions.currentUserSetPhoneStatus(status))

        mainApi.updateUserStatus(status)
            .catch(() => {
                showErrorNotification("Ошибка при обновлении статуса. Повторите попытку позже.")
                dispatch(currentUserActions.currentUserSetPhoneStatus(4))
            })
    },

    currentUserUpdateLocations: (newLocations: { id: number, name: string, status: boolean }[], onFinish: () => void, onError: () => void) => async () => {
        mainApi.updateUserLocations(newLocations)
            .then((response) => {
                if (response.status === 200) {
                    onFinish()
                }
            })
            .catch(() => {
                showErrorNotification("Ошибка при обновлении локаций. Повторите попытку позже.")
                onError()
            })
    },
}
