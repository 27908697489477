import React, { FC } from "react"

import MainLayout from "../../common/components/layouts/MainLayout"
import AdsPage from "../../common/components/pages/ads/AdsPage"

const Home: FC = () => {
    return (
        <MainLayout pageTitle="Объявления">
            <div className="page-wrapper">
                {/* <PageHead title="Объявления" /> */}

                <AdsPage />
            </div>
        </MainLayout>
    )
}

export default Home
