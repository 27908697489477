import React from "react"

import {
    PictureOutlined,
    LoadingOutlined,
    DownloadOutlined,
    CloseCircleOutlined,
} from "@ant-design/icons"
import { Button, Tooltip } from "antd"
type Props = {
    status:string
    onProcessPhoto:()=>void,
    onDownloadPhoto:()=>void
}
const PhotoStatusButton = ({ status, onProcessPhoto, onDownloadPhoto }:Props) => {
    const handleButtonClick = () => {
        switch (status) {
            case null:
                onProcessPhoto()
                break
            case "completed":
                onDownloadPhoto()
                break
        }
    }

    const renderIcon = () => {
        switch (status) {
            case null:
                return <PictureOutlined />
            case "pending":
            case "processing":
                return <LoadingOutlined />
            case "completed":
                return <DownloadOutlined />
            case "failed":
                return <CloseCircleOutlined />
            default: return ""
        }
    }

    const renderTooltipText = () => {
        switch (status) {
            case null:
                return "Обработать фото"
            case "pending":
            case "processing":
                return "Фото в обработке"
            case "completed":
                return "Скачать фото"
            case "failed":
                return "Ошибка при обработке фото"
            default: return ""
        }
    }

    return (
        <Tooltip title={renderTooltipText()}>
            <Button
                size="small"
                icon={renderIcon()}
                onClick={handleButtonClick}
                disabled={status === "failed" || status === "pending" || status === "processing"}
            />
        </Tooltip>
    )
}

export default PhotoStatusButton
