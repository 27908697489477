import axios, { AxiosResponse } from "axios"

import { axiosConfigAuth, axiosRefreshConfig } from "../middleware"
import {
    API_AUTH_LOGIN,
    API_AUTH_LOGOUT,
    // API_AUTH_RECOVERY,
    API_AUTH_REFRESH,
    // API_AUTH_RESET
} from "../urls"

export const authApi = {

    login: async (username: string, password: string): Promise<AxiosResponse> => {
        const data = {
            username: username,
            password,
        }

        return axios.post(API_AUTH_LOGIN, data, axiosRefreshConfig())
    },

    logout: async (token: string): Promise<AxiosResponse> => {
        return axios.get(API_AUTH_LOGOUT, {
            headers: {
                Token: token,
                withCredentials: true,
            },
        })
    },

    // resetPassword: async (email: string): Promise<AxiosResponse> => {
    //     const data = {
    //         email: email
    //     }
    //     return axios.post(API_AUTH_RESET, data)
    // },

    // recoveryPassword: async (token: string, password: string, fingerprint: string): Promise<AxiosResponse> => {
    //     const data = {
    //         password,
    //         token,
    //         fingerprint
    //     }
    //
    //     return axios.post(API_AUTH_RECOVERY, data)
    // },

    refreshToken: async (fingerprint: string): Promise<AxiosResponse> => {
        const data = {
            fingerprint: fingerprint
        }

        return axios.post(API_AUTH_REFRESH, data, axiosRefreshConfig())
    }
}
