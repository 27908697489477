import { favoriteSliceActions } from "./favoriteSlice"
import { adsApi } from "../../../common/api/endpoints/adsApi"
import { mainApi } from "../../../common/api/endpoints/mainApi"
import { handleResponseError } from "../../../common/api/middleware"
import { IAdsListPayload } from "../../../types/ads"
import { TAppDispatch } from "../../store"

export const favoriteActions = {
    ...favoriteSliceActions,

    favoriteFetchData: (data: IAdsListPayload, withoutLoading: boolean, onFinish?: () => void) => async (dispatch: TAppDispatch) => {
        !withoutLoading && dispatch(favoriteActions.setFavoriteLoading(true))

        adsApi.fetchFavoritesData(data)
            .then((response) => {
                if (response.status === 200) {
                    const meta = {
                        total: response.data.total,
                        totalPages: response.data.totalPages,
                        start: response.data.start,
                        end: response.data.end,
                        currentPage: response.data.currentPage,
                        pageSize: response.data.pageSize
                    }
                    let rows: any[] = Array.from(response.data.rows)
                    try {
                        rows.some((v) => v)
                    } catch (e) {
                        rows = []
                    }

                    dispatch(favoriteActions.setFavoriteTableData({ rows, meta }))
                    onFinish && onFinish()
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
            })
            .finally(() => {
                !withoutLoading && dispatch(favoriteActions.setFavoriteLoading(false))
            })
    },

    favoriteSendCall: (id: number) => async (dispatch: TAppDispatch) => {
        dispatch(favoriteActions.setFavoriteLoading(true))

        mainApi.sendCall(id)
            .catch((err) => {
                handleResponseError(dispatch, err, {
                    400: "Проверьте подключение к приложению и повторите попытку"
                })
            })
            .finally(() => {
                dispatch(favoriteActions.setFavoriteLoading(false))
            })
    }
}
