import React from "react"

import { Button, Result } from "antd"
import { useNavigate } from "react-router-dom"

import { useActions } from "../../common/hooks/useActions"

const ErrorPage = () => {
    const navigate = useNavigate()
    const { authLogout } = useActions()

    return (
        <div className="global-loading-container">
            <Result
                status="error"
                title="Ошибка при обращении к серверу"
                subTitle="Пожалуйста повторите позднее или обратитесь в поддержку."
                extra={
                    <div>
                        <Button
                            type="primary"
                            onClick={() => navigate("/")}
                            style={{ marginRight: 10 }}
                        >
                            На главную
                        </Button>

                        <Button
                            type="default"
                            onClick={() => authLogout()}
                        >
                            Выйти
                        </Button>
                    </div>
                }
            />
        </div>
    )
}

export default ErrorPage
