import React, { FC, useEffect, useState } from "react"

import {
    DatabaseOutlined, GlobalOutlined, LoadingOutlined, PhoneOutlined,
    StopOutlined, UserOutlined
} from "@ant-design/icons"
import {Avatar, Badge, Button, Checkbox, Col, Dropdown, Layout, Menu, Modal, Row, Space, Spin} from "antd"

import styles from "./Header.module.scss"
import { useActions } from "../../hooks/useActions"
import { useTypedSelector } from "../../hooks/useTypedSelector"
import { updateArrayItem } from "../../utils/data"
import {showErrorNotification, showSuccessNotification} from "../../utils/ui"
import {mainApi} from "../../api/endpoints/mainApi";
import {useAllowedRoles} from "../../hooks/useAllowedRoles";
import {adminRole} from "../../constants/roles";

const Header: FC = () => {
    const isAdmin = useAllowedRoles([ adminRole ])
    const [ modalOpen, setModalOpen ] = useState(false)
    const [ checkedList, setCheckedList ] = useState<{id: number, status: boolean, name: string}[]>([])

    const { name, user_locations_list, user_phone_status, user_app_status, locationsLoading } = useTypedSelector((state) => state.currentUser)
    const { currentUserGetLocations, currentUserUpdatePhoneStatus, currentUserUpdateLocations } = useActions()
    const [photoBalance,setPhotoBalance] = useState({
        date:"",
        "photo-balance":0,
        isLoading:true
    })
    useEffect(() => {
        const fetchPhotoBalance = async () => {
            setPhotoBalance((prev)=>({...prev,isLoading: true}))
            try{
                const { data } = await mainApi.getUserPhotoBalance()
                setPhotoBalance(({...data,isLoading: false}))
            }catch(err){
                showErrorNotification("Произошла ошибка при получении баланса фотографий.")
                setPhotoBalance((prev)=>({...prev,isLoading: false}))
                console.log(err)
            }
        }
        isAdmin && fetchPhotoBalance()
        if (modalOpen) {
            currentUserGetLocations()
        }
    }, [ modalOpen ])

    useEffect(() => {
        setCheckedList(user_locations_list)
    }, [ user_locations_list ])

    const userStatusMenuItems = [
        {
            key: "1",
            label: (
                <a onClick={() => currentUserUpdatePhoneStatus(4)}>
                    <StopOutlined style={{ color: "red" }} /> Не беспокоить
                </a>
            )
        },
        {
            key: "2",
            label: (
                <a onClick={() => currentUserUpdatePhoneStatus(2)}>
                    <UserOutlined style={{ color: "green" }} /> Готов
                </a>
            )
        },
    ]

    return (
        <Layout.Header
            className={styles.headerContainer}
        >
            <div />

            <Space direction={"horizontal"}>
                {
                    isAdmin && <Spin spinning={photoBalance.isLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
                        <div style={{ display:"flex",rowGap:2,flexDirection:"column" }}>
                            <span style={{ lineHeight:"initial" }}>Доступных фото: {photoBalance["photo-balance"]}</span>
                            <span style={{ lineHeight:"initial" }}>Тариф до: {photoBalance.date}</span>
                        </div>
                    </Spin>

                }

                <div className={styles.userInfo}>
                    <Avatar size={28} icon={<UserOutlined />} />
                    <span>
                        {name || "Пользователь"}
                    </span>
                </div>

                    <Button
                        type="text"
                        icon={<DatabaseOutlined />}
                        onClick={() => setModalOpen(true)}
                    />

                    <Badge dot style={{ backgroundColor: user_app_status === 2 ? "#52c41a" : "red" }}>
                        <Button
                            type="text"
                            icon={<GlobalOutlined />}
                        />
                    </Badge>

                    <Dropdown
                        overlay={<Menu items={userStatusMenuItems}/>}
                        placement="bottomRight"
                        trigger={[ "click" ]}
                    >
                        <Badge dot style={{ backgroundColor: user_phone_status === 2 ? "#52c41a" : "red" }}>
                            <Button
                                type="text"
                                icon={<PhoneOutlined />}
                            />
                        </Badge>
                    </Dropdown>
            </Space>

            <Modal
                title="Выбор локаций"
                open={modalOpen}
                onCancel={() => setModalOpen(false)}
                onOk={() => {
                    currentUserUpdateLocations(checkedList, () => {
                        showSuccessNotification("Выбранные локации обновлены")
                        setModalOpen(false)
                    }, () => null)
                }}
                okText="Сохранить"
                okButtonProps={{ loading: locationsLoading }}
            >
                <Space direction="vertical">
                    <Space>
                        <Button
                            size="small"
                            onClick={() => {
                                setCheckedList(
                                    checkedList.map((item) => ({
                                        ...item,
                                        status: true
                                    }))
                                )
                            }}
                        >
                            Выбрать все
                        </Button>

                        <Button
                            size="small"
                            onClick={() => {
                                setCheckedList(
                                    checkedList.map((item) => ({
                                        ...item,
                                        status: false
                                    }))
                                )
                            }}
                        >
                            Отчистить все
                        </Button>
                    </Space>

                    <div style={{ width: "100%", display: "flex", flexDirection: "column", margin: "5px 2px" }}>
                        {
                            checkedList.map((item, index) => (
                                <Row>
                                    <Col>
                                    <span key={index} onClick={() => {
                                        let newList = updateArrayItem(item.id, { status: !item.status }, checkedList)
                                        setCheckedList(newList)
                                    }}>
                                        <Checkbox checked={item.status}>
                                            {item.name}
                                        </Checkbox>
                                    </span>
                                    </Col>
                                </Row>
                            ))
                        }
                    </div>
                </Space>
            </Modal>
        </Layout.Header>
    )
}

export default Header
