import React, { FC } from "react"

import MainLayout from "../../common/components/layouts/MainLayout"
import PageHead from "../../common/components/page-header/PageHeader"
import StaffPage from "../../common/components/pages/staff/StaffPage"

const Staff: FC = () => {
    return (
        <MainLayout pageTitle="Сотрудники">
            <div className="page-wrapper">
                <PageHead title="Сотрудники" />

                <StaffPage />
            </div>
        </MainLayout>
    )
}

export default Staff
