import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { IAccessData, IAccessState } from "./types"

const initialState: IAccessState = {
    accessData: {
        ip: "",
        access: false,
        data: []
    },
    isLoading: false,
    isUpdate: false
}

export const accessSlice = createSlice({
    name: "access",
    initialState: initialState,
    reducers: {
        setAccessData: (state, action: PayloadAction<IAccessData>) => {
            state.accessData = action.payload
        },
        setAccessLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        setAccessUpdate: (state, action: PayloadAction<boolean>) => {
            state.isUpdate = action.payload
        }
    }
})

export const accessSliceReducer = accessSlice.reducer
export const accessSliceActions = accessSlice.actions
