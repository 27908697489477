import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { IAppState } from "./types"

const initialState: IAppState = {
    isGlobalLoading: false,
    isGlobalError: false,
}

export const appSlice = createSlice({
    name: "app",
    initialState: initialState,
    reducers: {
        setAppLoading: (state, action: PayloadAction<boolean>) => {
            state.isGlobalLoading = action.payload
        },
        setAppGlobalError: (state, action: PayloadAction<boolean>) => {
            state.isGlobalError = action.payload
        }
    }
})

export const appSliceActions = appSlice.actions
export const appSliceReducer = appSlice.reducer

