import React, { FC, useEffect } from "react"

import { DeleteOutlined, ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons"
import { Button, Modal, Space, Table } from "antd"
import { ColumnsType } from "antd/es/table"

import { useActions } from "../../../../hooks/useActions"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"

const BlackListPage: FC = () => {

    const { blData, isLoading } = useTypedSelector((state) => state.bl)
    const { blFetchData, blDelete } = useActions()

    useEffect(() => {
        blFetchData()
    }, [])

    const staffColumns: ColumnsType<any> = [
        {
            title: "Значение",
            dataIndex: "value",
            key: "value",
        },
        {
            title: "Источник",
            dataIndex: "source",
            key: "source",
            render: (value) => (
                <span>{value.name}</span>
            )
        },
        {
            title: "Дата добавления",
            dataIndex: "date",
            key: "date",
        },

        {
            title: "",
            dataIndex: "actions",
            key: "actions",
            width: 60,
            render: (value, record) => (
                <Space>
                    <Button
                        icon={<DeleteOutlined />}
                        onClick={() => {
                            Modal.confirm({
                                title: "Вы уверены что хотите удалить запись?",
                                icon: <ExclamationCircleOutlined/>,
                                okText: "Да",
                                okType: "danger",
                                cancelText: "Нет",
                                onOk() {
                                    blDelete(record.value, record.source.id, () => {
                                        blFetchData()
                                    })
                                },
                            })
                        }}
                        danger
                    />
                </Space>
            )
        },
    ]

    return (
        <div className="page-content">
            <Table
                dataSource={blData}
                columns={staffColumns}
                scroll={{ x: 870 }}
                loading={{
                    spinning: isLoading,
                    indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />
                }}
                pagination={false}
                bordered
            />
        </div>
    )
}

export default BlackListPage