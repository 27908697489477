import { FormInstance, message, notification } from "antd"

export const displayLanguageByKey = (key: string) => {
    switch (key) {
        case "ru":
            return "Русский"

        default:
            return "-"
    }
}

export const showErrorMessage = (text: string) => {
    message.open({
        content: text,
        type: "error",
        style: { marginTop: 40 }
    })
}

export const showSuccessMessage = (text: string) => {
    message.open({
        content: text,
        type: "success",
        style: { marginTop: 40 }
    })
}

export const showErrorNotification = (text: string, duration = 5) => {
    notification.open({
        type: "error",
        message: "Ошибка!",
        description: text,
        duration: duration,
        placement: "bottomRight"
    })
}

export const showSuccessNotification = (text: string, duration = 5) => {
    notification.open({
        type: "success",
        message: "Успех!",
        description: text,
        duration: duration,
        placement: "bottomRight"
    })
}

export const showInfoNotification = (text: string, duration = 5) => {
    notification.open({
        type: "info",
        message: "Внимание!",
        description: text,
        duration: duration,
        placement: "bottomRight"
    })
}

export const showFormErrors = (form: FormInstance, errors: {[key: string]: string}) => {
    Object.entries(errors).forEach(([ key, val ]) => {
        form.setFields([
            {
                name: key,
                errors: [ val ]
            }
        ])
    })
}
