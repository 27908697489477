import React, { FC, useEffect, useState } from "react"

import { LoadingOutlined } from "@ant-design/icons"
import { Button, Card, Col, DatePicker, Form, Input, InputNumber, Row, Select, Space, Spin } from "antd"
import { useDispatch } from "react-redux"

import { IAdsFilterLists } from "../../../../types/ads"
import { adsApi } from "../../../api/endpoints/adsApi"
import { handleResponseError } from "../../../api/middleware"
import { adminRole } from "../../../constants/roles"
import { useActions } from "../../../hooks/useActions"
import { useAllowedRoles } from "../../../hooks/useAllowedRoles"

interface IProps {
    isLoading: boolean
}

const FilterTable: FC<IProps> = ({ isLoading }) => {
    const dispatch = useDispatch()
    const [ form ] = Form.useForm()
    const isAdmin = useAllowedRoles([ adminRole ])

    const [ isLoadingLists, setIsLoadingLists ] = useState(false)
    const [ filterLists, setFilterLists ] = useState<IAdsFilterLists>({
        userList: [],
        statusList: [],
        metroList: [],
        sourceList: [],
        groupList: [],
        roomList: []
    })

    const { setAdsTableFilters, setAdsFetching } = useActions()

    const fetchListsData = () => {
        setIsLoadingLists(true)

        adsApi.fetchLists()
            .then((response) => {
                if (response.status === 200) {
                    const listData = response.data

                    setFilterLists({
                        userList: listData.operator_list,
                        statusList: listData.call_status_list,
                        metroList: listData.metro_list,
                        sourceList: listData.source_list,
                        groupList: listData.group_list,
                        roomList: listData.list_of_rooms
                    })
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
            })
            .finally(() => {
                setIsLoadingLists(false)
            })
    }

    useEffect(() => {
        fetchListsData()
    }, [])

    const onSubmitFilter = (values: any) => {
        const start = values.data ? values.data[0] : null
        const end = values.data ? values.data[1] : null

        const data = {
            ...values,
            data: { start: start, end: end }
        }

        setAdsTableFilters(data)
        setAdsFetching(true)
    }

    return (
        <Spin spinning={isLoadingLists || isLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
            <Card style={{ width: "100%", marginTop: 20 }}>
                <Form
                    form={form}
                    onFinish={onSubmitFilter}
                    name="basic"
                >
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="data"
                            >
                                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                {/* @ts-ignore */}
                                <DatePicker.RangePicker
                                    format="DD.MM.YYYY"
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="phone"
                            >
                                <Input placeholder="Телефон" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                name="status_id"
                            >
                                <Select mode="multiple"  placeholder="Статус">
                                    {
                                        filterLists?.statusList?.map((item, index) => (
                                            <Select.Option key={index} value={item.id}>
                                                {item.name}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="metro_id"
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Метро"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (`${option?.children ?? ""}`).toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (`${optionA?.children ?? ""}`).toLowerCase().localeCompare((`${optionB?.children ?? ""}`).toLowerCase())
                                    }
                                >
                                    {
                                        filterLists?.metroList?.map((item, index) => (
                                            <Select.Option key={index} value={item.id}>
                                                {item.name}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="source_id"
                            >
                                <Select mode="multiple"  placeholder="Источник">
                                    {
                                        filterLists?.sourceList?.map((item, index) => (
                                          <Select.Option key={index} value={item.id}>
                                              {item.name}
                                          </Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                name="group_id"
                            >
                                <Select mode="multiple"  placeholder="Группа">
                                    {
                                        filterLists?.groupList?.map((item, index) => (
                                            <Select.Option key={index} value={item.id}>
                                                {item.name}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="room_id"
                            >
                                <Select mode="multiple"  placeholder="Кол-во комнат">
                                    {
                                        filterLists?.roomList?.map((item, index) => (
                                            <Select.Option key={index} value={item.id}>
                                                {item.name}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="item_id"
                            >
                                <Input placeholder="Номер объявления" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="price_start"
                            >
                                <InputNumber placeholder="Цена От" style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="price_end"
                            >
                                <InputNumber placeholder="Цена До" style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    {
                        isAdmin &&
                            <Row gutter={24}>
                              <Col span={8}>
                                  <Form.Item
                                      name="user_id"
                                  >
                                      <Select mode="multiple" placeholder="Оператор">
                                          {
                                              filterLists?.userList?.map((item, index) => (
                                                <Select.Option key={index} value={item.id}>
                                                    {item.name}
                                                </Select.Option>
                                              ))
                                          }
                                      </Select>
                                  </Form.Item>
                              </Col>
                            </Row>
                    }
                </Form>
                <Space>
                    <Button
                        type="primary"
                        onClick={() => form.submit()}
                    >
                        Применить
                    </Button>
                    <Button
                        type="default"
                        onClick={() => {
                            form.resetFields()
                            setAdsTableFilters({
                                data: { start: "", end: "" },
                                user_id: 0,
                                status_id: 0,
                                metro_id: 0,
                                phone: "",
                                source_id: 0,
                                group_id: 0,
                                room_id: 0,
                                item_id: "",
                            })
                            setAdsFetching(true)
                        }}
                    >
                        Сбросить
                    </Button>
                </Space>
            </Card>
        </Spin>
    )
}

export default FilterTable
