import React, { FC } from "react"

import MainLayout from "../../../common/components/layouts/MainLayout"
import PageHead from "../../../common/components/page-header/PageHeader"
import CallsSettingsPage from "../../../common/components/pages/settings/calls/CallsSettingsPage"

const CallSettings: FC = () => {
    return (
        <MainLayout pageTitle="Настройка звонков">
            <div className="page-wrapper">
                <PageHead title="Настройка звонков" />

                <CallsSettingsPage />
            </div>
        </MainLayout>
    )
}

export default CallSettings
