export const SERVER_BASE_URL = process.env.REACT_APP_API_URL || "https://api.smalldata.pro"
export const API_BASE_URL = `${SERVER_BASE_URL}/api`

// auth routes
// export const API_AUTH_RESET = `${API_BASE_URL}/password-recovery`
// export const API_AUTH_RECOVERY = `${API_BASE_URL}/change-password`
export const API_AUTH_LOGIN = `${API_BASE_URL}/auth/login`
export const API_AUTH_LOGOUT = `${API_BASE_URL}/auth/logout`
export const API_AUTH_REFRESH = `${API_BASE_URL}/auth/refresh-token`

// main routes
export const API_USER_GET_INFO = `${API_BASE_URL}/get-user-info`
export const API_USER_PHOTO_BALANCE = `${API_BASE_URL}/photo-balance`

export const API_USER_GET_STATUS = `${API_BASE_URL}/connection-status`
export const API_USER_UPDATE_STATUS = `${API_BASE_URL}/update-user-status`
export const API_USER_GET_LOCATIONS = `${API_BASE_URL}/list-of-regions-user`
// export const API_USER_ADD_LOCATION = `${API_BASE_URL}/add-location-user`
// export const API_USER_DEL_LOCATION = `${API_BASE_URL}/del-location-user`
export const API_USER_UPDATE_LOCATIONS = `${API_BASE_URL}/location-user`

// staff
export const API_STAFF_GET_DATA = `${API_BASE_URL}/list-of-users`
export const API_STAFF_CREATE = `${API_BASE_URL}/create-user`
export const API_STAFF_UPDATE = `${API_BASE_URL}/update-user`
export const API_STAFF_DELETE = `${API_BASE_URL}/delete-user`
export const API_STAFF_UPD_DATA = `${API_BASE_URL}/get-user`
export const API_STAFF_ROLES_LIST = `${API_BASE_URL}/list-of-roles`
export const API_STAFF_GROUPS_LIST = `${API_BASE_URL}/list-of-groups`

// params settings
export const API_PARAMS_GET_DATA = `${API_BASE_URL}/list-of-params`
export const API_PARAMS_UPDATE = `${API_BASE_URL}/update-param`

// access
export const API_ACCESS_GET = `${API_BASE_URL}/list-of-access`
export const API_ACCESS_ADD = `${API_BASE_URL}/create-access`
export const API_ACCESS_EDIT = `${API_BASE_URL}/update-access`
export const API_ACCESS_DELETE = `${API_BASE_URL}/delete-access`

// call
export const API_CALL_SET_STATUS = `${API_BASE_URL}/call-end`
export const API_CALL_GET_DATA = `${API_BASE_URL}/edit-status-call`
export const API_CALL_SEND = `${API_BASE_URL}/queue-new`

// ads
export const API_ADS_DATA = `${API_BASE_URL}/items`
export const API_ADS_GET_FILTER_LISTS = `${API_BASE_URL}/filter-list`
export const API_ADS_PROCESS_PHOTO = `${API_BASE_URL}/task/add`
export const API_ADS_DOWNLOAD_PHOTO = `${API_BASE_URL}/task/download`

// black list
export const API_BL_GET_DATA = `${API_BASE_URL}/black-of-list`
export const API_BL_ADD = `${API_BASE_URL}/add-black-list`
export const API_BL_DEL = `${API_BASE_URL}/del-black-list`

// favorites
export const API_FAV_LIST = `${API_BASE_URL}/favorites`
export const API_FAV_ADD = `${API_BASE_URL}/add-to-favorites`
export const API_FAV_DELETE = `${API_BASE_URL}/del-to-favorites`
