import React, { FC } from "react"

import { PhoneOutlined } from "@ant-design/icons"
import { Button } from "antd"

import { errorTagColor, successTagColor } from "../../../constants/colors"
import ColorTag from "../../ui/color-tag/ColorTag"

interface ISourceProps {
  source: string
}

export const SourceView: FC<ISourceProps> = ({ source }) => {
    switch (source) {
        case "Авито":
            return (

        <ColorTag
            color={successTagColor}
        >
          {source}
        </ColorTag>
            )
        case "ЦИАН":
            return (
        <ColorTag color="#1677ff">
          {source}
        </ColorTag>
            )
        case "ЮЛА":
            return (
        <ColorTag color="#7092fe">
          {source}
        </ColorTag>
            )

        default:
            return (
        <ColorTag
            color="#ef961f"
        >
          {source}
        </ColorTag>
            )
    }
}

interface IStatusProps {
  status: string
  id: number
  setEditOpen: (v: boolean) => void
  setEditId: (v: number) => void
  sendCall: (id: number) => void
}

export const StatusView: FC<IStatusProps> = ({ status, id, setEditId, setEditOpen, sendCall }) => {
    const openEdit = () => {
        setEditId(id)
        setEditOpen(true)
    }

    switch (status) {
        case "Наша квартира":
            return (
                <>
          <ColorTag
              color={successTagColor}
              onClick={() => openEdit()}
              pointer
          >
            {status}
          </ColorTag>

          <Button
              type="primary"
              size="small"
              icon={<PhoneOutlined />}
              onClick={() => sendCall(id)}
              color={successTagColor}
              style={{ margin: "4px 0 6px 0" }}
          />
                </>
            )
        case "Звонок":
            return (
        <ColorTag color="#2c78b2">
          {status}
        </ColorTag>
            )
        case "Новое":
            return (
                <>
          <ColorTag defaultColor>
            {status}
          </ColorTag>

          <Button
              type="primary"
              size="small"
              icon={<PhoneOutlined />}
              onClick={() => sendCall(id)}
              color={successTagColor}
              style={{ margin: "4px 0 6px 0" }}
          />
                </>
            )

        default:
            return (
                <>
          <ColorTag
              color={errorTagColor}
              onClick={() => openEdit()}
              pointer
          >
            {status}
          </ColorTag>

          <Button
              type="primary"
              size="small"
              icon={<PhoneOutlined />}
              onClick={() => sendCall(id)}
              color={successTagColor}
              style={{ margin: "4px 0 6px 0" }}
          />
                </>
            )
    }
}
