import React, { FC } from "react"

import "antd/dist/reset.css"
import "./styles/root.scss"
import CallWrapper from "./common/components/wrappers/callWrapper/CallWrapper"
import GlobalErrorWrapper from "./common/components/wrappers/global-error-wrapper/GlobalErrorWrapper"
import AppRouter from "./router"

const App: FC = () => {

    return (
        <div id="app" className="App">
            <GlobalErrorWrapper>
                <CallWrapper>
                    <AppRouter />
                </CallWrapper>
            </GlobalErrorWrapper>
        </div>
    )
}

export default App
