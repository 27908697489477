import { paramsSliceActions } from "./paramsSlice"
import { paramsApi } from "../../../common/api/endpoints/paramsApi"
import { handleResponseError } from "../../../common/api/middleware"
import { TAppDispatch } from "../../store"

export const paramsActions = {
    ...paramsSliceActions,

    paramsFetchData: () => async (dispatch: TAppDispatch) => {
        dispatch(paramsActions.setParamsLoading(true))
        dispatch(paramsActions.setParamsData([]))

        paramsApi.fetchData()
            .then((response) => {
                if (response.status === 200) {
                    dispatch(paramsActions.setParamsData(response.data))
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
            })
            .finally(() => {
                dispatch(paramsActions.setParamsLoading(false))
            })
    },

    paramsUpdate: (data: { id: number, value: string }, onFinish: () => void, onFormError: (errors: {[key: string]: string}) => void) => async (dispatch: TAppDispatch) => {
        dispatch(paramsActions.setParamsLoading(true))

        paramsApi.updateData(data)
            .then((response) => {
                if (response.status === 200) {
                    onFinish()
                }
            })
            .catch((err) => {
                if (err.response?.status === 400) {
                    onFormError(err.response.data)
                }

                handleResponseError(dispatch, err)
            })
            .finally(() => {
                dispatch(paramsActions.setParamsLoading(false))
            })
    },
}
