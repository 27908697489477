import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { IItemState, IItemRow } from "./types"

const initialState: IItemState = {
    itemData: [],
    isLoading: false,
    isUpdate: false
}

export const itemSlice = createSlice({
    name: "item",
    initialState: initialState,
    reducers: {
        setItemData: (state, action: PayloadAction<IItemRow[]>) => {
            state.itemData = action.payload
        },
        setItemLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        setItemUpdate: (state, action: PayloadAction<boolean>) => {
            state.isUpdate = action.payload
        }
    }
})

export const itemSliceReducer = itemSlice.reducer
export const itemSliceActions = itemSlice.actions
