import axios, { AxiosResponse } from "axios"

import { axiosConfigAuth } from "../middleware"
import {
    API_CALL_GET_DATA,
    API_CALL_SEND,
    API_CALL_SET_STATUS,
    API_USER_GET_INFO,
    API_USER_GET_LOCATIONS,
    API_USER_GET_STATUS,
    API_USER_PHOTO_BALANCE,
    API_USER_UPDATE_LOCATIONS,
    API_USER_UPDATE_STATUS
} from "../urls"

export const mainApi = {
    getUserInfo: (): Promise<AxiosResponse> => {
        return axios.get(API_USER_GET_INFO, axiosConfigAuth())
    },

    getUserStatus: (): Promise<AxiosResponse> => {
        return axios.get(API_USER_GET_STATUS, axiosConfigAuth())
    },
    getUserPhotoBalance: (): Promise<AxiosResponse> => {
        return axios.get(API_USER_PHOTO_BALANCE,axiosConfigAuth())
    },

    getUserLocations: (): Promise<AxiosResponse> => {
        return axios.get(API_USER_GET_LOCATIONS, axiosConfigAuth())
    },

    updateUserStatus: (status: 2 | 4): Promise<AxiosResponse> => {
        return axios.get(`${API_USER_UPDATE_STATUS}/${status}`, axiosConfigAuth())
    },

    updateUserLocations: (values: { id: number, name: string, status: boolean }[]): Promise<AxiosResponse> => {
        return axios.post(API_USER_UPDATE_LOCATIONS, values, axiosConfigAuth())
    },

    sendCallStatus: (id: number, status: number, note: string, operator_id?: number): Promise<AxiosResponse> => {
        const data = {
            id: id,
            status_id: status,
            notes: note,
        }
        const newData = operator_id ? {
            ...data,
            operator_id: operator_id,
        } : data

        return axios.post(API_CALL_SET_STATUS, newData, axiosConfigAuth())
    },

    fetchCallData: (id: number): Promise<AxiosResponse> => {
        return axios.get(`${API_CALL_GET_DATA}/${id}`, axiosConfigAuth())
    },

    sendCall: (id: number): Promise<AxiosResponse> => {
        return axios.get(`${API_CALL_SEND}/${id}`, axiosConfigAuth())
    }
}
