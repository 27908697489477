import React, { FC } from "react"

import MainLayout from "../../common/components/layouts/MainLayout"
import PageHead from "../../common/components/page-header/PageHeader"
import AccessPage from "../../common/components/pages/access/AccessPage"

const Access: FC = () => {
    return (
        <MainLayout pageTitle="Доступ">
            <div className="page-wrapper">
                <PageHead title="Доступ" />

                <AccessPage />
            </div>
        </MainLayout>
    )
}

export default Access
