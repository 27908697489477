import React, { FC, useEffect } from "react"

import { Form, Input, Modal } from "antd"

import { useActions } from "../../../hooks/useActions"
import { useTypedSelector } from "../../../hooks/useTypedSelector"
import { showFormErrors, showSuccessNotification } from "../../../utils/ui"

interface Props {
    open: boolean
    setOpen: (value: boolean) => void
    id: number
    address: string
    description: string
}

const EditModal: FC<Props> = ({ open, setOpen, id, address, description }) => {

    const [ form ] = Form.useForm()

    const { isLoading } = useTypedSelector((state) => state.access)
    const { accessUpdate, accessFetchData } = useActions()

    useEffect(() => {
        if (open) {
            form.setFieldValue("address", address)
            form.setFieldValue("description", description)
        }
    }, [ open ])

    const updateAccess = (values: any) => {
        const parsedValues = {
            id: id,
            address: values.address,
            description: values.description
        }

        accessUpdate(parsedValues, () => {
            setOpen(false)
            showSuccessNotification("Досутп добавлен")
            accessFetchData()
        }, (errors) => {
            showFormErrors(form, errors)
        })
    }

    return (
        <Modal
            title="Редактирование доступа"
            open={open}
            onOk={() => form.submit()}
            onCancel={() => setOpen(false)}
            okText="Добавить"
            cancelText="Отмена"
            okButtonProps={{ loading: isLoading }}
            destroyOnClose
        >
            <Form
                className="un-form__wrapper"
                form={form}
                onFinish={updateAccess}
                layout="vertical"
                requiredMark={false}
            >

                <Form.Item
                    label="IP"
                    name="address"
                    rules={[ { required: true, message: "Поле обязательное" } ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Описание"
                    name="description"
                    rules={[ { required: true, message: "Поле обязательное" } ]}
                >
                    <Input />
                </Form.Item>

            </Form>
        </Modal>
    )
}

export default EditModal
