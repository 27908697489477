import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { IAdsData, IAdsState, ITableBanners, ITableMeta, ITableFilters } from "./types"

const initialState: IAdsState = {
    isLoading: false,
    isFetching: true,
    tableData: [],
    tableMetaData: {
        total: 0,
        totalPages: 0,
        start: 0,
        end: 0,
        currentPage: 1,
        pageSize: 30
    },
    tableFilters: {
        data: { start: "", end: "" },
        user_id: 0,
        status_id: 0,
        metro_id: 0,
        phone: "",
        source_id: 0,
        group_id: 0,
        room_id: 0,
        item_id: "",
    },
    bannerData: {
        total: 0,
        answered: 0,
        unanswered: 0,
        conversion: "0%",
        not_first: "",
        not_call: ""
    }
}

export const adsSlice = createSlice({
    name: "ads",
    initialState: initialState,
    reducers: {
        setAdsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        setAdsFetching: (state, action: PayloadAction<boolean>) => {
            state.isFetching = action.payload
        },
        setAdsTableFilters: (state, action: PayloadAction<ITableFilters>) => {
            state.tableFilters = action.payload
        },
        setAdsTableData: (state, action: PayloadAction<{rows: IAdsData[], meta: ITableMeta}>) => {
            state.tableData = action.payload.rows
            state.tableMetaData = action.payload.meta
        },
        setAdsTableMetaData: (state, action: PayloadAction<ITableMeta>) => {
            state.tableMetaData = action.payload
        },
        setAdsBannerData: (state, action: PayloadAction<ITableBanners>) => {
            state.bannerData = action.payload
        },
    }
})

export const adsSliceActions = adsSlice.actions
export const adsSliceReducer = adsSlice.reducer

