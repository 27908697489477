import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { IBlState, IBlRow } from "./types"

const initialState: IBlState = {
    blData: [],
    isLoading: false
}

export const blSlice = createSlice({
    name: "bl",
    initialState: initialState,
    reducers: {
        setBlData: (state, action: PayloadAction<IBlRow[]>) => {
            state.blData = action.payload
        },
        setBlLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
    }
})

export const blSliceReducer = blSlice.reducer
export const blSliceActions = blSlice.actions
