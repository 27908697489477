import React, { FC, useState } from "react"

import { LoadingOutlined } from "@ant-design/icons"
import { Layout, Spin } from "antd"
import { Content } from "antd/es/layout/layout"

import { useTypedSelector } from "../../hooks/useTypedSelector"
import Footer from "../footer/Footer"
import Header from "../header/Header"
import Nav from "../nav/Nav"

type Props = {
    children: React.ReactElement,
    withHead?: boolean,
    withFooter?: boolean,
    clean?: boolean
    pageTitle?: string
}

const MainLayout: FC<Props> = ({ children, withHead = true, withFooter = false, clean = false, pageTitle }) => {

    document.title = pageTitle ? `${pageTitle}` : "CRM"

    const { isGlobalLoading } = useTypedSelector((state) => state.app)
    const [ collapsed, setCollapsed ] = useState(false)

    return isGlobalLoading ? (
        <div className="global-loading-container">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
    ) : (
        <Layout style={{ minHeight: "100vh" }}>
            <Layout>
                {
                    clean ? null :
                    <Layout.Sider
                        theme="dark"
                        collapsed={collapsed}
                        onCollapse={value => setCollapsed(value)}
                        className="layout__nav-sider"
                    >
                        <Nav />
                    </Layout.Sider>
                }

                <Layout>
                    { withHead ? <Header /> : null }

                    <Layout.Content className="layout__content">
                        <main>
                            {children}
                        </main>
                        
                    </Layout.Content>

                    { withFooter ? <Footer/> : null }
                </Layout>
            </Layout>
        </Layout>
    )
}

export default MainLayout
