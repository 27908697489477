import { blSliceActions } from "./blSlice"
import { blApi } from "../../../common/api/endpoints/blApi"
import { handleResponseError } from "../../../common/api/middleware"
import { TAppDispatch } from "../../store"

export const blActions = {
    ...blSliceActions,

    blFetchData: () => async (dispatch: TAppDispatch) => {
        dispatch(blActions.setBlLoading(true))
        dispatch(blActions.setBlData([]))

        blApi.fetchData()
            .then((response) => {
                if (response.status === 200) {
                    dispatch(blActions.setBlData(response.data))
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
            })
            .finally(() => {
                dispatch(blActions.setBlLoading(false))
            })
    },

    blDelete: (value: string, id: number, onFinish: () => void) => async (dispatch: TAppDispatch) => {
        dispatch(blActions.setBlLoading(true))

        blApi.delete(value, id)
            .then((response) => {
                if (response.status === 200) {
                    onFinish()
                }
            })
            .catch((err) => {
                handleResponseError(dispatch, err)
            })
            .finally(() => {
                dispatch(blActions.setBlLoading(false))
            })
    },
}
