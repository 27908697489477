import React, { FC, useEffect, useState } from "react"

import { LoadingOutlined } from "@ant-design/icons"
import { Form, Input, Modal, Select, Spin } from "antd"

import { useActions } from "../../../hooks/useActions"
import { showErrorNotification } from "../../../utils/ui"

interface Props {
    id: number
    open: boolean
    setOpen: (v: boolean) => void
}

const EditModal: FC<Props> = ({ id, open, setOpen }) => {
    const [ form ] = Form.useForm()

    const [ isLoading, setIsLoading ] = useState(false)
    const [ statusList, setStatusList ] = useState<any[]>([])
    const [ operatorsList, setOperatorsList ] = useState<any[]>([])

    const { currentUserSendCallStatus, adsGetCurrentAdData } = useActions()

    useEffect(() => {
        if (open) {
            setIsLoading(true)

            adsGetCurrentAdData(id, (data) => {
                form.setFieldValue("status", data.status_id)
                form.setFieldValue("note", data.notes)
                form.setFieldValue("operator_id", data.operator_id)
                setStatusList(data.status_list)
                setOperatorsList(data.operator_list)
                
                setIsLoading(false)
            }, () => {
                setIsLoading(false)
            })
        }
    }, [ open ])

    const onFinish = (values: any) => {
        setIsLoading(true)

        currentUserSendCallStatus(id, values.status, values.note, () => {
            setIsLoading(true)
            setOpen(false)
        }, () => {
            setIsLoading(true)
            showErrorNotification("Ошибка при изменении объявления, повторите попытку")
        }, values.operator_id)
    }

    return (
        <Modal
            title="Редактирование объявления"
            open={open}
            onOk={() => form.submit()}
            onCancel={() => setOpen(false)}
            okText="Сохранить"
            cancelText="Отмена"
            okButtonProps={{ loading: isLoading }}
            destroyOnClose
        >
            {
                isLoading ?
                    (
                        <div className="global-loading-container" style={{ height: 150 }}>
                            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                        </div>
                    ) : (
                        <Form
                            className="un-form__wrapper"
                            form={form}
                            onFinish={onFinish}
                            layout="vertical"
                            requiredMark={false}
                        >
                            <Form.Item
                                label="Оператор"
                                name="operator_id"
                            >
                                <Select>
                                    {
                                        operatorsList.map((item, index) => (
                                            <Select.Option value={item.id} key={index}>
                                                {item.name}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Статус"
                                name="status"
                            >
                                <Select>
                                    {
                                        statusList.map((item, index) => (
                                            <Select.Option value={item.id} key={index}>
                                                {item.name}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Заметка"
                                name="note"
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Form>
                    )
            }
        </Modal>
    )
}

export default EditModal