import { useTypedSelector } from "./useTypedSelector"

// roles:
// 1 - admin
// 2 - user
export const useAllowedRoles = (allowedRoles: number[]) => {
    const { role } = useTypedSelector((state) => state.currentUser)

    const containsRoles = (where: number[], what: number) => {
        if (where.includes(what, 0)) {
            return true
        }
        
        return false
    }

    return role && containsRoles(allowedRoles, role)
}
