import React, { FC, useEffect } from "react"

import { Alert, Form, Input, Modal, Tag } from "antd"

import { useActions } from "../../../../hooks/useActions"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import { showFormErrors, showSuccessNotification } from "../../../../utils/ui"

interface Props {
    open: boolean
    setOpen: (value: boolean) => void
    id: number
    name: string
    desc: string
    value: string
}

const EditModal: FC<Props> = ({ open, setOpen, id, name, desc, value }) => {

    const [ form ] = Form.useForm()

    const { isLoading } = useTypedSelector((state) => state.params)
    const { paramsFetchData, paramsUpdate } = useActions()

    useEffect(() => {
        if (open) {
            form.setFieldValue("value", value)
        }
    }, [ open ])

    const updateParam = (values: { value: string }) => {
        const parsedValues = {
            id: id,
            value: values.value
        }

        paramsUpdate(parsedValues, () => {
            setOpen(false)
            showSuccessNotification("Параметр обновлен")
            paramsFetchData()
        }, (errors) => {
            showFormErrors(form, errors)
        })
    }

    return (
        <Modal
            title="Редактирование параметра"
            open={open}
            onOk={() => form.submit()}
            onCancel={() => setOpen(false)}
            okText="Сохранить"
            cancelText="Отмена"
            okButtonProps={{ loading: isLoading }}
            destroyOnClose
        >
            <Form
                className="un-form__wrapper"
                form={form}
                onFinish={updateParam}
                layout="vertical"
                requiredMark={false}
            >
                <Form.Item>
                    Параметр: <Tag>{name}</Tag>
                </Form.Item>

                <Form.Item>
                    <Alert
                        message={desc}
                        type="info"
                        showIcon
                    />
                </Form.Item>

                <Form.Item
                    label="Значение:"
                    name="value"
                    rules={[ { required: true, message: "Поле обязательное" } ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default EditModal
